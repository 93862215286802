<template>
  <AdminView>
    <h1 class="title-1">Étapes de réglage</h1>

    <div class="column">
      <SettingsStepsForm :settingsSteps="settingsSteps" />
    </div>
  </AdminView>
</template>


<script>
import AdminView from '@/components/AdminView'
import SettingsStepsForm from '@/components/Forms/SettingsStepsForm'

export default {
  name: 'SettingsSteps',
  components: {
    AdminView, SettingsStepsForm
  },
  data () {
    return {
      settingsSteps: [
        {
          name: 'Réglage de la hauteur',
          image: ''
        },
        {
          name: 'Aide anti-gravitaire',
          image: ''
        },
        {
          name: 'Largeur Appuis Iliaques',
          image: ''
        },
        {
          name: 'Largeur Selle',
          image: ''
        },
        {
          name: 'Assistance Oscillation Jambes',
          image: ''
        },
        {
          name: 'Profondeur Appuis Iliaques',
          image: ''
        },
        {
          name: 'Hauteur Appuis Iliaques',
          image: ''
        },
        {
          name: 'Dossier',
          image: ''
        },
        {
          name: 'Formation à l\'utilisation d\'Oxilio',
          image: ''
        },
        {
          name: 'Checklist de bons Réglages',
          image: ''
        },
        {
          name: 'Formation à l\'utilisation de la plateforme',
          image: ''
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
