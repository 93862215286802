<template>
  <div class="form">
    <div v-for="(settingsStep, i) in settingsSteps" :key="i"
      class="row"
    >
      <Input
        class="name"
        title="Nom"
        v-model="settingsStep.name"
      />
      <InputFile
        title="Icône"
        type="image"
        v-model="settingsStep.image"
      />
    </div>

    <div class="row buttons-container">
      <Button
        class="save"
        title="Sauvegarder les modifications"
        type="primary"
      />
    </div>
  </div>
</template>

<script>
import Input from '@/components/Forms/Fields/Input'
import InputFile from '@/components/Forms/Fields/InputFile'
import Button from '@/components/Button'

export default {
  name: 'SettingStepsForm',
  props: ['settingsSteps'],
  components: {
    Input, InputFile, Button
  }
}
</script>

<style lang="scss" scoped>
.form{
  border-bottom: 1px solid $light-grey-color;
  padding: 10px 0;
  margin-top: 20px;

  .row{
    align-items: flex-start;
    
    .name {
      flex: 1;
    }
  }

  .buttons-container{
    justify-content: center;
    margin: 20px 0;

    div {
      flex: 0;
    }
  }
}
</style>
